.lets-talk{
	position: relative;
	background: #221E41;
	text-align: center;
	padding: 215px 0 90px;
	color: #fff;
	min-height: var(--lets-talk-min-height);
	display: flex;
	justify-content: center;
	flex-direction: column;
	.text-container{
		max-width: 900px;
		margin: 0 auto;
		.btn{
			vertical-align: bottom; 
			margin: 0 0 16px 20px;
		}
	}
	h3{
		font-size: 113px;
		font-weight: 400;
		line-height: 1.06; 
		margin: 0;
		display: inline; 
		strong{
			font-weight: 600;
			text-transform: uppercase;
		}
	}
	@media screen and (min-width: breakpoint-min(xxl, $grid-breakpoints)){ //default : >= 1400px
		padding: 21.5rem 0 9rem;
		// min-height: calc(var(--window-height) - 40rem);
		.text-container{
			max-width: 90rem;
			.btn{
				margin: 0 0 1.6rem 2rem;
			}
		}
		h3{
			font-size: 11.3rem;
		}
	}
	@media screen and (max-width: breakpoint-max(xxl, $grid-breakpoints)){ //default : < 1400px
	}
	
	@media screen and (min-width: 1900px){ //default : >= 1900px
		//default height : 1200
		// rem : 13.333
		padding-top: 20.63rem;
		padding-bottom: 7.8rem;
		h3{
			font-size: 10.75rem;
		}
	}
	@media screen and (min-width: 2500px){ //default : >= 2500px
		//default height : 1440
		// rem : 17.778 
		padding-top: 15.75rem;
		padding-bottom: 7.14rem;
	}

	@media screen and (max-width: breakpoint-max(xl, $grid-breakpoints)){ //default : < 1200px
		h3{
			font-size: 100px;
		}
	}
	@media screen and (max-width: breakpoint-max(lg, $grid-breakpoints)){ //default : < 992px
		padding: 140px 0;
		h3{
			font-size: 85px;
		}
		.text-container{
			.btn{
				margin-bottom: 7px; 
			}
		}
	}
	@media screen and (max-width: breakpoint-max(md, $grid-breakpoints)){ //default : < 768px
		padding: 120px 0;
		h3{
			font-size: 75px;
			display: block;
		}
		.text-container{
			.btn{
				margin: 24px 0 0 0;
    			// padding: 22px 50px;
			}
		}
	}
	@media screen and (max-width: breakpoint-max(sm, $grid-breakpoints)){ //default : < 640px
		padding: 100px 0 64px;
		h3{
			font-size: 40px;
		}
	}
}