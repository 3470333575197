footer{
	position: relative;
	padding-bottom: 30px;
	background: #221E41;
	color: #fff;

	.footer-row-1{
		display: flex;
		justify-content: space-between;
		position: relative;
		border-top: solid 1px rgba(#fff, .4);
		padding-top: 100px;
		padding-bottom: 65px;

		&::before,&::after{
			content: '';
			position: absolute;
			top: -18px;
			width: 18px;
			height: 18px;
		}
		&::before{
			left: 0;
			border-top-right-radius:100%;
			background: #00ADFF;
		}
		&::after{
			right: 0;
			border-top-left-radius:100%;
			background: #6937FF;
		}	
	}

}
.footer-contact{
	display: flex;
	flex-direction: column;
	margin-right: auto;
	max-width: 365px;
	font-size: 17px;
	line-height: 1.32;
	font-weight: 300; 
	.city{
		font-family: 'ClashDisplay-Variable', sans-serif;
		font-size: 31px;
		line-height: 1.25;
		font-weight: 500;
		margin: 0 0 15px;
	}
	a{
		display: inline-block;
	}
	.address{
		margin: 0;
		line-height: inherit;
		color: rgba(#fff, .65);
	}
	.mail{
		text-decoration: underline;
		margin-bottom: 1.32em; 
		color: rgba(#fff, .65);
	}
}
.foot-nav{
	padding-top: 18px;
	margin: 0 40px;
	min-width: 140px;
	h4{
		font-size: 17px;
		line-height: 1.24;
		letter-spacing: 0.02em;
		margin: 0 0 20px;
	}
	.footer-nav-list{
		list-style: none;
		padding: 0;
		margin: 0;
		color: rgba(#fff, .65);
		a{
			font-weight: 300;
			padding: 5px 0;
			display: inline-block;
		}
	}
	.footer-brand{
		line-height: 1;
	}
}
.footer-row-2{
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: rgba(#FFFFFF, .04); 
	color: rgba(#fff, .65);
	border-radius: 8px;
	padding: 20px 40px;
	margin: 0 -40px;
	.copy{
		margin: 0;
		font-size: 15px;
		font-weight: 300;
		line-height: 1.3333;
	}
}

@media screen and (min-width: breakpoint-min(xxl, $grid-breakpoints)){ //default : >= 1400px
	//default height : 900
	// rem : 10
	footer{
		padding-bottom: 3rem;

		.footer-row-1{
			padding-top: 10rem;
			padding-bottom: 6.5rem;

			&::before,&::after{
				top: -1.8rem;
				width: 1.8rem;
				height: 1.8rem;
			}
		}
	}

	.footer-row-2{
		border-radius: .8rem;
		padding: 2.0rem 4.0rem;
		margin: 0 -4.0rem;
		.copy{
			font-size: 1.5rem;
		}
		.footer-brand{
			img{
				height: 2.6rem;
			}
		}
	}
	.footer-contact{
		max-width: 36.5rem;
		font-size: 1.7rem;
		.city{
			font-size: 3.1rem;
			margin: 0 0 1.5rem;
		}
	}
	.foot-nav{
		padding-top: 1.8rem;
		margin: 0 4.0rem;
		min-width: 14.0rem;
		h4{
			font-size: 1.7rem;
			margin: 0 0 2.0rem;
		}
		.footer-nav-list{
			a{
				padding: .5rem 0;
			}
		}
	}
}

@media screen and (min-width: 1900px){ //default : >= 1900px
		//default height : 1200
		// rem : 13.333
	footer{
		.footer-row-1{
			padding-top: 8.1rem;
			padding-bottom: 6rem;
		}
	}
	.footer-contact{
		font-size: 1.55rem;
		.city{
			font-size: 2.35rem;
			margin-bottom: 1.28rem;
		}
	}
	.footer-row-2{
		padding: 1.88rem 3rem;
		margin: 0 -3rem;
		.footer-brand{
			img{
				height: 2.7rem; 
			}
		}
	}
}
@media screen and (min-width: 2500px){ //default : >= 2500px
	footer{
		.footer-row-1{
			padding-top: 6.3rem;
		}
	}
	.footer-contact{
		.city{
		}
	}
}
@media screen and (max-width: breakpoint-max(xxl, $grid-breakpoints)){ //default : < 1400px
	footer{
	}
	.footer-row-2{
	    padding-left: var(--bs-gutter-x,20px);
	    padding-right: var(--bs-gutter-x,20px);
	    margin: 0 calc(var(--bs-gutter-x,20px) * -1); 
	}
}
@media screen and (max-width: breakpoint-max(xl, $grid-breakpoints)){ //default : < 1200px
	footer{
		.foot-nav-1{
			margin-left: 0;
		}
		.foot-nav-3{
			margin-right: 0;
		}
	}
	.foot-nav{
		margin: 0 25px;
	}
}
@media screen and (max-width: breakpoint-max(lg, $grid-breakpoints)){ //default : < 992px
	footer{
		.footer-row-1{
			flex-wrap: wrap;
			padding-bottom: 30px;
		}
	}
	.footer-contact{
	    max-width: 100%;
	    width: 100%;
	    flex: 0 0 100%;
	    margin-bottom: 40px;
	}
	.foot-nav{
		margin: 0;
		padding-bottom: 40px;
		min-width: 165px;
	}
}
@media screen and (max-width: breakpoint-max(md, $grid-breakpoints)){ //default : < 768px
	footer{
	}
	.footer-row-2{
		margin: 0 calc(16px - var(--bs-gutter-x,20px));
	}
	.footer-contact{
		.city{
			font-size: 25px;
			margin-bottom: 12px;
		}
	}
}
@media screen and (max-width: breakpoint-max(sm, $grid-breakpoints)){ //default : < 640px
	footer{
		padding-bottom: 16px; 
		.footer-row-1{
			padding-top: 60px;
			padding-bottom: 0;
		}
	}
	.footer-row-2{
		padding: 18px 16px;
		.copy{
			font-size: 13px;
		}
		img{
			height: 21px; 
		}
	}
	.foot-nav{
		padding-top: 0;
		h4{
			margin-bottom: 12px;
		}
	}
}



