body{
    --ease-in: cubic-bezier(0.49,0.025,0.65,0.65);
    --ease-out: cubic-bezier(0.28,0.44,0.49,1);
    --ease-in-out: cubic-bezier(0.49,0.025,0.49,1);
    --bounce: cubic-bezier(0.6,0,0.1,1.4);
    --ease-in-soft: cubic-bezier(0.49,0.025,0.685,1);
    --ease-out-soft: cubic-bezier(0.28,0,0.49,1);
    --ease-in-out-soft: cubic-bezier(0.72,0,0.28,1);
    --ease-in-out-hard: cubic-bezier(0.77,0,0.175,1);
    --ease-garret: cubic-bezier(0.5,0,0,1);
}
@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes fade-out {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@keyframes fade-out {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@-webkit-keyframes translate-up-0 {
    0% {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes translate-up-0 {
    0% {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
@-webkit-keyframes translate-up-25 {
    0% {
        transform: translateY(25%);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes translate-up-25 {
    0% {
        transform: translateY(25%);
    }
    to {
        transform: translateY(0);
    }
}
@-webkit-keyframes translate-up-0-masked {
    0% {
        transform: translateY(103%);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes translate-up-0-masked {
    0% {
        transform: translateY(103%);
    }
    to {
        transform: translateY(0);
    }
}
@-webkit-keyframes translate-up-100 {
    0% {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100%);
    }
}
@keyframes translate-up-100 {
    0% {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100%);
    }
}
@-webkit-keyframes translate-down-0 {
    0% {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes translate-down-0 {
    0% {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}
@-webkit-keyframes translate-down-100 {
    0% {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}
@keyframes translate-down-100 {
    0% {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}
@-webkit-keyframes overlay-slide-left {
    0% {
        transform: translateX(calc((var(--grid-width) + var(--grid-gutter) - var(--grid-padding) * 2) / var(--grid-column-count) * 8 + var(--grid-padding)));
    }
    to {
        transform: translateX(0);
    }
}
@keyframes overlay-slide-left {
    0% {
        transform: translateX(calc((var(--grid-width) + var(--grid-gutter) - var(--grid-padding) * 2) / var(--grid-column-count) * 8 + var(--grid-padding)));
    }
    to {
        transform: translateX(0);
    }
}