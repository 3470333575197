html {
	--font_size_title: 100px;
}

.is-ready{
	.gradient-image{
		display: block;
	}
}
.gradient-image{
	// content: '';
	display: none;
	position: fixed;
	top: 0; bottom: 0;
	left: 0; right: 0;

	// width: 100vw; height: 100vh;

	background-image: url('../img/process-bg-new.png');
	background-size: cover; 
	background-position: center;

	transition: opacity cubic-bezier(0.72,0,0.28,1) .5s;
	opacity: 0;

	z-index: -1;
}
body{
	font-family: 'Switzer-Variable', sans-serif;
	background: #FFFFFF;
	color: #000000;
	font-size: 17px; 
	--bs-gutter-x: 24px;
	--window-height: 100vh;
	--lets-talk-min-height : calc(100vh - 400px);
		
	@media screen and (min-width: breakpoint-min(lg, $grid-breakpoints)){ //default : >= 992px
		overflow: hidden;
	}

	transition: color cubic-bezier(0.72,0,0.28,1) 1s;
	&.bg-gradient{
		color: #fff;
		.gradient-image{
			opacity: 1;
		}
	}
}
section{
	overflow: hidden; 
}
h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5{
	font-family: 'ClashDisplay-Variable', sans-serif;
	font-weight: 500;
}

a{
	color: inherit;
	text-decoration: none; 
	&:hover{
		color: inherit;
		text-decoration: underline; 
	}
}
p, ul{
	font-size: 17px;
	line-height: 1.59; 
}

.section-title{
	position: relative;
	font-size: 73px;
	line-height: 1.23;
	margin-bottom: 72px;
}

.grtyoutube-popup {
	background: rgba(0,0,0,.75);
	.grtyoutube-popup-content{
		max-width: 1280px;
		max-height: 800px;
		width: 90%;
		height: 90%;
	}
	.grtyoutube-iframe{
	}
	.grtyoutube-popup-close {
		position: fixed;
		top: 15px;
		right: 15px;
		width: 42px; height: 42px;
		background: url('../img/ic-close.svg') no-repeat center;
	}
}
.container{
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--bs-gutter-x, 20px);
    padding-right: var(--bs-gutter-x, 20px);
    max-width: initial;
}

@media screen and (min-width: breakpoint-min(xxl, $grid-breakpoints)){ //default : >= 1400px
	html {
	    font-size: .6944444444444444vw;
	} 
	body{
		--bs-gutter-x: 70px;
		// font-size: 1.7rem;
	}
	/* p, ul{
		font-size: 1.7rem;
	}
	.section-title{
		font-size: 7.3rem;
		margin-bottom: 7.2rem;
	}

	.grtyoutube-popup {
		.grtyoutube-popup-content{
			max-width: 128rem;
			max-height: 80rem;
		}
		.grtyoutube-popup-close {
			top: 1.5rem;
			right: 1.5rem;
			width: 4.2rem; height: 4.2rem;
			background-size: 2rem 2rem;
		}
	} */
}
@media screen and (min-width: 1900px){ //default : >= 1900px
	body{
		--bs-gutter-x: 90px;
		font-size: 1.65rem;
	}
	p, ul{
		font-size: 1.65rem;
	}
	.section-title{
		font-size: 7.28rem;
		margin-bottom: 7.8rem;
	}
}
@media screen and (min-width: 2500px){ //default : >= 2500px
	body{
		--bs-gutter-x: 120px;
		font-size: 1.41rem;
	}
	p, ul{
		font-size: 1.41rem;
	}
}

@media screen and (max-width: breakpoint-max(xxl, $grid-breakpoints)){ //default : < 1400px 
}
@media screen and (max-width: breakpoint-max(xl, $grid-breakpoints)){ //default : < 1200px
}
@media screen and (max-width: breakpoint-max(lg, $grid-breakpoints)){ //default : < 992px
	html{
		font-size: 2.7777777777778vw; 
	}
	.section-title{
		font-size: 60px;
	}
}
@media screen and (max-width: breakpoint-max(md, $grid-breakpoints)){ //default : < 768px
	body{
		&::before{
			background-image: url('../img/process-bg-mobile.png');
		}
	}
	.section-title{
		font-size: 50px;
		margin-bottom: 50px;
	}
}
@media screen and (max-width: breakpoint-max(sm, $grid-breakpoints)){ //default : < 640px
	body{
		// --bs-gutter-x: 15px;  
	}
	.section-title{
		font-size: 40px;
	}
}
@media screen and (max-width: 479.98px){ //default : < 480px

	.section-title{
		font-size: 33px;
		margin-bottom: 4.8rem;
	}
}