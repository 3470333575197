.clients{
	padding-top: 140px;
	padding-bottom: 140px;

	.client-list{
		display: flex;
		flex-wrap: wrap;
		margin-left: 81px;
		margin-right: -31px;
	}

	.client-container{
		width: 50%;
		padding:  0 31px;
	}
	.client-item,
	.client-item i::after,
	.client-item i img{
		transition: all cubic-bezier(.215,.61,.355,1) .25s;
	}
	.client-item{
		position: relative;
		display: flex;
		flex-direction: column;
		border-top: solid 1px #000;
		height: 140px;
		padding: 30px 25px 0 0;
		overflow: hidden;
		h4{
			font-size: 25px;
			line-height: 1.4;
			margin-bottom: 0;
		}
		span{
			margin-top: 7px;
			display: block;
			color: #777777;
		}
		i{
			font-family: 'ClashGrotesk-Variable', sans-serif;
			position: absolute;
			display: flex;
			// flex-direction: column;
			align-items: flex-end;
			top: 30px; right: 24px;
			height: 30px;
			// overflow: hidden;
			font-size: 25px;
			color: #777777;
			font-style: normal; 
			&::after{
				content: ''; 
				position: absolute;
				right: 0;
				display: block; 
				width: 24px; 
				min-height: 30px;
				background-image: url('../img/ic-arrow-right.svg');
				background-repeat: no-repeat;
				background-position: center;
			}
			img{
				height: 30px;
				transform: translateX(-35px);
				opacity: 0;
			}
		}
		&:hover, &:focus{
			background: #F8F8F8;
			padding-left: 25px;
			text-decoration: none;
			&::after{
				right: 28px;
			}
			h4{
				text-decoration: underline;
			}

			i{
				&::after{
					opacity: 0;
					transform: translateX(35px); 
				}
				img{
					// margin-top: -30px;
					transform: translateX(0px);
					opacity: 1;
				}
			}
		}
		&:focus{
			h4{
				color: #404040;
			}
		}
	}

	@media screen and (min-width: breakpoint-min(xxl, $grid-breakpoints)){ //default : >= 1400px
		padding-top: 14rem;
		padding-bottom: 14rem;
		.client-list{
			margin-left: 8.1rem;
			margin-right: -3.1rem;
		}
		.client-container{
			padding: 0 3.1rem;
		}
		.client-item{
			height: 14.5rem;
			padding: 3rem 2.5rem 0 0;
			h4{
				font-size: 2.5rem;
			}
			span{
				margin-top: .7rem;
			}
			i{
				top: 3rem; right: 2.4rem;
				height: 3rem; 
				font-size: 2.5rem;
				&::after{
					width: 2.4rem; 
					min-height: 3rem;
					background-size: 2.2rem 1.7rem;
				}
				img{
					height: 3rem;
					transform: translateX(-3.5rem);
				}
			}
			&:hover, &:focus{
				padding-left: 2.5rem;
				&::after{
					right: 2.8rem;
				}

				i{
					&::after{
						transform: translateX(3.5rem); 
					}
					img{
						transform: translateX(0rem);
					}
				}
			}
		}
	}
	
@media screen and (min-width: 1900px){ //default : >= 1900px
		//default height : 1200
		// rem : 13.333
		padding-top: 13.5rem;
		padding-bottom: 13.5rem;

		.client-list{
			margin-left: 7.5rem;
			margin-right: -3.75rem;
		}
		.client-container{
			padding: 0 3.75rem;
		}
		.client-item{
			height: 14rem;
			padding: 3rem 2.4rem 0 0;
			h4{
				font-size: 2.5rem;
				line-height: 1.3; 
			}
			span{
				margin-top: .7rem;
			}
			i{
				font-size: 2.4rem; 
			}
			&:hover, &:focus{
				padding-left: 2.4rem;
			}
		}
	}
	@media screen and (min-width: 2500px){ //default : >= 2500px
		//default height : 1440
		// rem : 17.778 
		.client-item{
			padding: 3rem 2.4rem 0 0;
			h4{
				font-size: 2.5rem;
				line-height: 1.3; 
			}
			span{
				font-size: 1.65rem;
				margin-top: .86rem;
			}
			&:hover, &:focus{
				padding-left: 2.4rem;
			}
		}
	}


	@media screen and (max-width: breakpoint-max(xxl, $grid-breakpoints)){ //default : < 1400px
	}
	@media screen and (max-width: breakpoint-max(xl, $grid-breakpoints)){ //default : < 1200px
		.client-list{
			margin: 0 -20px;
		}
		.client-container{
			padding: 0 20px;
		}
	}
	@media screen and (max-width: breakpoint-max(lg, $grid-breakpoints)){ //default : < 992px
		.client-container{
			width: 100%;
		}
	}
	@media screen and (max-width: breakpoint-max(md, $grid-breakpoints)){ //default : < 768px
		padding-top: 100px;
		padding-bottom: 72px;
	}
	@media screen and (max-width: breakpoint-max(sm, $grid-breakpoints)){ //default : < 640px
		.client-item{
			height: initial;
		    padding-top: 24px;
		    padding-right: 24px;
		    padding-bottom: 48px;
		    min-height: 130px;
		    h4{
		    	font-size: 21px;
		    	line-height: 1.48;
		    }
		    span{
		    	margin-top: 8px;
		    	line-height: 1.29;
		    }
		    i{
		    	top: 25px;
		    	right: 20px;
		    	height: 25px;
		    	font-size: 21px;
		    	&::after{
		    		min-height: 25px;
		    		background-size: 21px 25px;
		    	}
		    	img{
		    		height: 25px;
		    	}
		    }
		    &:hover, &:focus{
				padding-left: 15px;
				i{
					&::before{
						min-height: 25px;
					}
					img{
						margin-top: -25px;
					}
				}
			}
		}
	}
}