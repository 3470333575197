body{
	--window-height: 100vh;
}
.bg-gradient{
	.process{
		background-color: transparent;
		.process-item-box{
			.process-number{
				border-color: #fff;
				color: #fff;
			}
			h4{
				color: #000000;
				background: #FFFFFF;
			}
		}
		.process-details{
			&::before{ 
				background-color: #fff;
			}
		}
		.process-detail{
	    	.key-process{
	    		ul{
	    			li{ 
	    				color: #fff;
	    				&::before{
	    					border-color: #fff; 
	    				}
	    			}
	    		}
	    	}
		}
	}
}
.lets-talk-wrapper{
	margin-top: calc(var(--window-height) * -1); 
}
.bg-gradient-trigger{
	position: absolute;
	min-height: 1px;
	// background: red;
	width: 100%;
}
.process{ //.horizontal-section
	--minus-position: 0px;

	--section-title-top: calc(var(--nav-height) + 16px);
	position: relative;
	background-color: #f4f4f4;
	transition: background-color cubic-bezier(0.72,0,0.28,1) .65s;
	min-height: var(--window-height); 
	.process-trigger{
		position: relative;
		min-height: var(--window-height);
	}
	.section-title{
		// position: absolute;
		position: relative;
		margin: var(--section-title-top) 0 0;
		top: 0;
		left: 0; right: 0;
		height: 0;
		z-index: 20;
		// margin: 0 0 -100vh;
		.container{
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
		}
		h2{
			position: relative;
			font-size: 31px;
			line-height: 40px;
			margin: 0; 
		} 
		.step{
			font-family: 'ClashDisplay-Variable', sans-serif; 
			font-size: 21px;
			line-height: 27px; 
		}
	}
	--horizontal-delay-start: 50vh;
	.process-scroller-trigger{
		position: absolute;
		margin-top: var(--horizontal-delay-start);
	}
	.process-scroller{
		// display: flex;
		position: absolute;
		z-index: 50;
		top: 0;
	}

	.process-items{ 
	    display: flex;
	}

	.process-item-box{
		height: var(--window-height);
		width: 100vw;
		flex: 0 0 100vw;
		// overflow: hidden; 
		.container, .container-fluid{
			height: 100%;
		}
		.wrapper-spacer{
			// min-height: calc( var(--nav-height, 100px) + 50px);
			// height: 17.7vh;
		}
		.wrapper{
			// padding-bottom: 100px;
			padding-bottom: 318px;
			padding-bottom: calc(290px - var(--minus-position, 0px));

			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}

		.process-title{
			display: flex;
		}
		.process-number, h4{
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: 'ClashDisplay-Variable', sans-serif;
			font-weight: 600;
			line-height: 1.23;
			text-align: center;
			border-radius: 200px;
			margin: 0;
			transition-property: color,background-color,border-color;
			transition-timing-function: cubic-bezier(0.72,0,0.28,1);
			transition-duration: .65s;
		}
		.process-number{ 
			font-size: 120px;
			border: 4px solid #000;
			color: #000;
			background: rgba(255, 255, 255, 0.01);
			min-width: 338px;
			-webkit-backdrop-filter: blur(24px);
  			backdrop-filter: blur(24px);
			padding: 27px 60px 25px;
		}
		h4{
			font-size: 150px;
			color: #fff;
			background: #000;
			padding: 19px 80px 16px;
		}
	}

	$transition_duration_in : 1.5s;
	$transition_duration_out : .5s;
	.process-detail{
		display: flex;
		// border-top: solid 1px rgba(#FFFFFF, 0.4);
		padding: 48px 0 0;
		margin: 64px 0 0; 
		opacity: 0; 
		transform: translateX(-15px); 
		p{
			font-size: 25px;
			line-height: 1.4;
			margin: 0;
			color: #FFFFFF;
			max-width: 600px;
			letter-spacing: -0.01em;
			transition: color cubic-bezier(0.72,0,0.28,1) .65s;
			strong{
				font-weight: 500; 
				color: rgba(255, 255, 255, 1);
			}
		}
	}
	.key-process{
		max-width: 426px;
		padding-right: 62px;
		ul{
			display: flex;
			flex-wrap: wrap;
			column-gap: 8px;
			row-gap: 12px;
			list-style: none;
			margin: 0; padding: 0;
			li{
				position: relative;
				text-transform: uppercase;
				// font-size: 15px; 
				// line-height: 1.133;
				color: #000;
				font-size: 13px;
				line-height: 1.3;
				padding: 10px 15px;
				transition: color cubic-bezier(.215,.61,.355,1) .65s;
				&::before{
					content: '';
					position: absolute;
					top: 0; right: 0; bottom: 0; left: 0;
					border-radius: 109px;
					border: solid 1px #000;
					opacity: .25;
					transition: border-color cubic-bezier(.215,.61,.355,1) .65s;
				}
			}
		}
	}

	.process-details{
		// position: absolute;
		position: relative;
		top: 0;
		height: calc(var(--window-height) - var(--nav-height));
		width: 100vw;
		z-index: 30;
		&::before{
			content: '';
			height: 1px; 
			background-color: #000; 
			opacity: .4;
			position: absolute;
			left: var(--bs-gutter-x, 24px);
			right: var(--bs-gutter-x, 24px);  
			transition: background-color cubic-bezier(0.72,0,0.28,1) .65s;
			// bottom: 253px;
			bottom: calc(225px - var(--minus-position, 0px));
		}
		.container{
			position: relative;
			width: 100%; height: 100%;
		}
		.process-detail-item{
			position: absolute;
			left: var(--bs-gutter-x, 24px);
			right: var(--bs-gutter-x, 24px);  
			// bottom: 100px;
			bottom: calc(72px - var(--minus-position, 0px));
			&.active{
				.process-detail{
					opacity: 1;
		    		transform: translateX(0); 
		    		transition-duration: $transition_duration_in;
		    	}
			}
			&.active-mode-2{
				.process-detail{
					opacity: 0;
					transition-duration: $transition_duration_out;
					transform: translateX(0); 
		    		// transform: translateX(40px); 
		    	}
			}
		}
	}

	@media screen and (max-height: 700px){
		--minus-position: 20px;
	}

	@media screen and (min-width: breakpoint-min(xxl, $grid-breakpoints)){ //default : >= 1400px
		//default height : 900
		// rem : 10
		--minus-position: 0rem;
		.section-title{
			// height: 4rem; 
			margin: calc(var(--nav-height) + 1.6rem) 0 0;
			h2{
				font-size: 2.5rem;
			}
			.step{
				font-size: 2.1rem;
			}
		}
		.process-item-box{
			.wrapper{
				// padding-bottom: 10rem;
				padding-bottom: calc(29.0rem - var(--minus-position, 0rem));
			}
			.process-number, h4{
				border-radius: 20rem;
			}
			.process-number{
				font-size: 12rem;
				border-width: .4rem;
				min-width: 33.8rem;
				padding: 2.7rem 6rem 2.5rem;
			}
			h4{
				font-size: 15rem;
				padding: 1.9rem 8.0rem 1.6rem;
			}
		}
		.process-detail{
			border-width: .1rem;
			padding-top: 4.8rem;
			margin-top: 6.4rem; 
			transform: translateX(-1.5rem); 
			p{
				font-size: 2.5rem;
				max-width: 60.0rem;
			}
		}
		.key-process{
			max-width: 42.6rem;
    		padding-right: 6.2rem;
			ul{
				column-gap: .8rem;
				row-gap: 1.2rem;
				li{
					font-size: 1.3rem;
					padding: 1.0rem 1.5rem;
					&::before{
						border-radius: 10.9rem;
						border-width: .1rem;
					}
				}
			}
		}
		.process-details{
			&::before{
				bottom: calc(22.5rem - var(--minus-position, 0rem));
			}
			.process-detail-item{
				bottom: calc(7.2rem - var(--minus-position, 0rem));
			}
		}
		@media screen and (max-height: 700px){
			--minus-position: 2rem;
		}
	}

	
@media screen and (min-width: 1900px){ //default : >= 1900px
		//default height : 1200
		// rem : 13.333
		--minus-position: 0rem;
		.section-title{
			margin-top: 10.50rem;
			h2{
				font-size: 2.4rem;
			}
			.step{
				font-size: 1.58rem;
			}
		}
		.process-item-box{
			.wrapper{
				padding-bottom: calc(30.75rem - var(--minus-position, 0rem));
			}
		}
		.process-details{
			&::before{
				bottom: calc(24.15rem - var(--minus-position, 0rem));
			}
			.process-detail-item{
				bottom: calc(9rem - var(--minus-position, 0rem));
			}
		}

		@media screen and (max-height: 1080px){
			--minus-position: 2rem;
		}
	}
	@media screen and (min-width: 2500px){ //default : >= 2500px
		//default height : 1440
		// rem : 17.778 
		//default height : 1440
		// rem : 17.778 
		--minus-position: 0rem;
		.section-title{
			margin-top: 7.87rem;
			h2{

			}
		}
		.process-item-box{
			.wrapper{ 
				padding-bottom: calc(28.51rem - var(--minus-position, 0rem));
			}
		}
		.process-details{
			&::before{
				bottom: calc(21.93rem - var(--minus-position, 0rem));
			}
			.process-detail-item{
				bottom: calc(6.74rem - var(--minus-position, 0rem));
			}
		}

		@media screen and (max-height: 1240px){
			--minus-position: 2rem;
		}

	}

	@media screen and (max-width: breakpoint-max(xxl, $grid-breakpoints)){ //default : < 1400px
	}
	@media screen and (max-width: breakpoint-max(xl, $grid-breakpoints)){ //default : < 1200px
		
		@media screen and (max-height: 700px){
			
		}
		.process-item-box{
			.process-title{
				flex-direction: column; 
				align-items: flex-start; 
			}
			.process-number{
				font-size: 100px;
			}
			h4{
				font-size: 130px;
			}
		}
		.process-detail{
			p{ 
				padding-left: 30px;
			}
		}
		.key-process{
			padding-right: 0;
		}
	}
	@media screen and (max-width: breakpoint-max(lg, $grid-breakpoints)){ //default : < 992px
		--horizontal-delay-start: 20vh;
		.section-title{ 
			margin-top: var(--nav-height);
			h2{
				font-size: 25px;
				line-height: 30px;
			}
			.step{
				font-size: 21px;
				line-height: 30px; 
			}
		}
		.process-item-box{
			.wrapper{
				padding-bottom: calc(13rem + var(--nav-height)); 
			}
			.process-number, h4{
				border-radius: 6.54545rem; 
			}
			.process-number{
				font-size: 3.927rem;
				border-width: 1.3px; 
				min-width: 110px; 
				-webkit-backdrop-filter: blur(.785454rem);
				backdrop-filter: blur(.785454rem);
				padding: 1.1rem 2.7rem;
			}
			h4{
				font-size: 4.909rem;
				padding: .6rem 2.8rem;
			}
		}
		.process-detail{
    		flex-direction: column;
    		p{
    			font-size: 1rem;
    			line-height: 1.58;
    			padding-left: 0;
    			max-width: initial;
    		}
		}
		.key-process{
			max-width: 700px;
			margin-bottom: 1.2rem;
			padding-right: 0;
			ul{
				gap: .5rem;
				li{
					font-size: .8rem;
					padding: .5rem 1rem; 
				}
			}
		}
		.process-details{
			&::before{
				bottom: calc(11rem + var(--nav-height)); 
			}
			.process-detail-item{
				bottom: var(--nav-height);
			}
		}
	}
	@media screen and (max-width: breakpoint-max(md, $grid-breakpoints)){ //default : < 768px

	}
	@media screen and (max-width: breakpoint-max(sm, $grid-breakpoints)){ //default : < 640px
		.process-item-box{
			.wrapper{
				padding-bottom: 29.1rem;
			}
		}
		.process-detail{
    		flex-direction: column;
    		p{
    			font-size: 1.7rem;
    		}
		} 
		.key-process{
			margin-bottom: 1.6rem;
			ul{
				gap: .8rem;
				li{
					font-size: 1.1rem;
				}
			}
		}
		.process-details{
			&::before{
				bottom: 25.8rem; 
			}
			.process-detail-item{
				bottom: 4.2rem;  
			}
		}
	}
}