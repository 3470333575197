.is-ready{
    .logo-takeover {
        // -webkit-animation: translate-up-100 0.5s var(--ease-garret) forwards;
        // animation: translate-up-100 0.5s var(--ease-garret) forwards;
        transform: translate(0);
        opacity: 0;
        pointer-events: none;
    }
    .logo-takeover__mask {
        -webkit-animation: translate-up-100 0.5s var(--ease-garret) forwards;
        animation: translate-up-100 0.5s var(--ease-garret) forwards;
    }
    .logo-takeover__logo {
        -webkit-animation: translate-down-100 0.5s var(--ease-garret) forwards;
        animation: translate-down-100 0.5s var(--ease-garret) forwards;
    }
}
.logo-takeover {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0; top: 0;
    // pointer-events: none;
    background-color: #fff;
    z-index: 1050;
    transition: opacity .5s cubic-bezier(.215,.61,.355,1) .5s, transform 0s linear 1s; 
}
.logo-takeover__mask {
    flex: 0 0 30rem;
    max-width: 30vw;
    position: relative;
    overflow: hidden;
}
.logo-takeover__logo {
    position: relative;
    top: 0.1rem;
}
.logo-takeover__covers {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.logo-takeover__covers div {
    position: absolute;
    transform-origin: 50% 0;
}
.logo-takeover__covers div:first-child {
    width: 90.8%;
    height: 100%;
    left: 0;
    top: 0;
}
.logo-takeover__covers div:nth-child(2) {
    width: 8.6%;
    height: 38.2%;
    right: 0;
    top: 0;
}