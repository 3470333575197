// $nav_min_height : 80px; 

// $max_scroll_transition : 280;
// $nav_bg : #fff;
// $nav_bg_opacity : 1;

// $nav_height : 110px;
// $scrolled_nav_height : $nav_min_height;

html {
  --nav-height: 80px; 
  --nav-collapse-width: 50%;
}
.navbar{
	position: fixed;
	top: 0; left: 0; right: 0;
	z-index: 99;
	transition: transform .5s cubic-bezier(.3,.86,.36,.95);
	height: var(--nav-height);
	// max-height: 12vh;
	// min-height: $nav_min_height;
	border-bottom: solid 1px rgba(#000, .12);
	padding: 0;
    background: rgba(255,255,255, 1);
    backdrop-filter: blur(1px);
    z-index: 1040;
	.navbar-brand{
		padding: 0;
		line-height: 1;
		img{
			&.white{
				display: none;
			}
		}
	}
	.container, .inner-container, .container-fluid, .collapse, .navbar-nav, .nav-item{
		// height: 100%;
	}

	.inner-container{
		display: flex;
	    align-items: center;
	    justify-content: space-between;
	    width: 100%;
	}


	.collapse{
	    flex: 0 0 var(--nav-collapse-width, 50%);
	    width: var(--nav-collapse-width, 50%);
	}
	.navbar-wrapper{
		width: 100%; 
	}
	.navbar-nav{
    	width: 100%;
    	align-items: center;
		&.navbar-main{ 
			// margin-left: 540px;
		}
		&.navbar-talk{
			// margin-left: auto;
		}
		.nav-item{
			display: flex;
			align-items: center;
			position: relative;
			margin: 0 10px;
			&.first{
				margin-left: -15px;
			}
			&.last{
				margin-left: auto;
				.nav-link{
					font-weight: 600;
					text-decoration: underline;
					white-space: nowrap;
					transition: all cubic-bezier(.215,.61,.355,1) .5s;
					// overflow-x: hidden; 
					padding-right: 0;
					margin-left: 30px; 
					&::after{
						content: '➔';
						position: relative;
						display: inline-block;
						vertical-align: top;
						font-style: normal;
						margin-right: -30px;
						opacity: 0;
						overflow: hidden;
						transition: all cubic-bezier(.215,.61,.355,1) .5s;
						padding-left: 8px;
						width: 30px;
						height: initial;
						background: initial;
						left: initial; right: initial; 
						transform: none;
					}
					&:hover, &:focus{
						color: #221E41;
						text-decoration: none;
						margin-left: 0;
						&::after{
							margin-right: 0; 
							opacity: 1;
						}
					}
					&:focus{
						color: #404040;
					}
				}
			}
		}
		.nav-link{
			display: block;
			font-size: 17px;
			line-height: 1.5;  
			padding: 7px 14px 6px;
			color: #000;
			&::after{
				content: "";
				position: absolute;
				left: 15px; right: 15px; bottom: -1px;
				height: 0.05em;
				background: currentColor;
				will-change: transform;
				transform-origin: 100% 100%;
				transform: scaleX(0);
				pointer-events: none;
				transition-property: transform;
				transition-timing-function: cubic-bezier(.215,.61,.355,1);
				transition-duration: .5s;
			}
			&:hover{
				color: #221E41;
				&::after{
				    transform-origin: 0 0;
				    transform: scaleX(1);
				}
				.default-text{
					transform: translateY(-125%);
				}
				.hover-text{
					transform: none;
				}
			}
			.nav-link-inner{
				overflow: hidden;
				position: relative;
				display: block;
			}
			.default-text,
			.hover-text{
				display: block;
			    transition-property: transform;
			    transition-timing-function: cubic-bezier(.215,.61,.355,1);
			    transition-duration: .5s;
			}
			.hover-text{
				position: absolute;
				top: 0; left: 0;
				transform: translateY(125%);
			} 
		}
	}

	.navbar-toggler-icon{
		// background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='4' y='11' width='24' height='2' fill='black'/%3E%3Crect x='4' y='19' width='24' height='2' fill='black'/%3E%3C/svg%3E%0A");
		background-image: none;
		width: 32px; height: 32px;
		// display: flex;
		// align-items: center;
		// justify-content: center;
		// flex-direction: column;
		padding: 5px 0;
		i{
			display: block;
			width: 24px;
			height: 2px;
			background: #000000;
			transition: all .5s cubic-bezier(.3,.86,.36,.95);
			margin: 6px auto;
		}
	}
	.navbar-toggler{
		border: none; 
		padding: 3px; margin: -3px;
		&:focus{
			box-shadow: none;
		}
	}
}
.navbar-mobile-footer{
	display: none;
}

.navbar{
    transform: translateY(-100%);
}
[data-scroll-position="0"]{
	.navbar{
		transition-delay: .6s;
	}
}
.is-ready{
	.navbar{
	    transform: translateY(0);
	}
	[data-scroll-direction="down"],
	[data-scroll-by-nav="yes"]{ 
		.navbar{
		    transform: translateY(-100%);
		}
	}	
}


@media screen and (max-height: 700px){
	html {
		--nav-height: 80px; 
	}
}


@media screen and (min-width: breakpoint-min(xxl, $grid-breakpoints)){ //default : >= 1400px
	//default height : 900
	// rem : 10
	html {
		--nav-height: 8rem;
	}
	.navbar{
		.navbar-brand{
			img{
				height: 3.6rem;
			}
		}
		.navbar-nav{
			.nav-item{
				margin: 0 1rem;
				&.first{
					margin-left: -1.4rem;
				}
				&.last{
					.nav-link{
						margin-left: 3.0rem; 
						&::after{
							margin-right: -3.0rem;
							padding-left: .8rem;
							width: 3rem;
						}
					}
				}
			}
			.nav-link{
				font-size: 1.7rem;
				padding: .7rem 1.4rem .6rem;
				&::after{
					left: 1.4rem; right: 1.4rem; bottom: -.1rem;
				}
			}
		}
	}
}

@media screen and (min-width: 1900px){ //default : >= 1900px
		//default height : 1200
		// rem : 13.333
	html {
		--nav-height: 7.2rem;
	}
	.navbar{
		.navbar-brand{
			img{
				height: 3rem;
			}
		}
		.navbar-nav{
			.nav-item{
				margin: 0 1.2rem; 
				&.first{
					margin-left: -1.2rem; 
				}
			}
			.nav-link{
				font-size: 1.58rem;
				padding: .7rem 1.2rem .6rem; 
			}
		}
	}
}
@media screen and (min-width: 2500px){ //default : >= 2500px
	html {
		--nav-height: 6.3rem;
	}
	.navbar{
		.navbar-brand{
			img{
				height: 2.61rem;
			}
		}
		.navbar-nav{
			.nav-item{
				margin: 0 1rem; 
				&.first{
					margin-left: -1rem; 
				}
			}
			.nav-link{
				font-size: 1.41rem;
				padding: .7rem 1rem .6rem; 
			}
		}
	}
}

@media screen and (max-width: breakpoint-max(xxl, $grid-breakpoints)){ //default : < 1400px
}
@media screen and (max-width: breakpoint-max(xl, $grid-breakpoints)){ //default : < 1200px
	html {
		--nav-collapse-width: 65%;
	}
	.navbar{ 
		.navbar-nav{
			&.navbar-main{
				// margin-left: 300px;
			}
		}
	}
}
@media screen and (max-width: breakpoint-max(lg, $grid-breakpoints)){ //default : < 992px
	html {
		--nav-height: 80px; 
	}
	.navbar{
		.navbar-brand{
			img{
				height: 32px;
			}
		}
		.navbar-nav{
			&.navbar-main{
				// margin-left: 0;
				// margin-right: auto;
			}
		}
	}
}
@media screen and (max-width: breakpoint-max(md, $grid-breakpoints)){ //default : < 768px
	html {
		--nav-collapse-width: 100%;
		--nav-height: 72px; 
	}
	.navbar{ 
		background-color: #fff;
		transition-property: transform,background-color,border-color;
		transition-duration: .3s;
		.navbar-brand{
			padding: 0;
			position: relative;
			img{
				transition: opacity .3s cubic-bezier(.3,.86,.36,.95);
				&.white{
					display: block;
					position: absolute;
					top: 0;
					opacity: 0;
				}
			}
		}

		&.navbar-shown{
			background-color: #221E41;
			border-bottom-color: rgba(#FFFFFF, .12);
			transform: translateY(0px);
			.navbar-toggler-icon{
				i{
					background-color: #fff;
					transform: rotate(45deg) translate(4.5px,3.5px);
					&:last-child{
						transform: rotate(-45deg) translate(2px, -1px);
					}
				}
			}
			.navbar-brand{
				img{
					opacity: 0;
					&.white{
						opacity: 1;
					}
				}
			}
		}
		.navbar-nav{
			align-items: flex-start;
			.nav-item{
				margin: 4px 0; 
				&.first{
					margin-left: 0;
				}
				&.last{
					display: none;
					/* margin-left: initial; 
					.nav-link{
						margin-left: initial; 
						padding-right: 15px;
					} */
				}
			}
			.nav-link{
				font-size: 40px;
				line-height: 1.06;
				padding: 0;
				color: #fff;
				&:hover{
					color: #fff;
				}
				&::after{
					content: "";
					position: absolute;
					left: 0; right: 15px; bottom: -1px;
					height: 1px; 
				}
			}
		}
		.navbar-collapse{
			position: absolute;
			left: 0;
			right: 0;
			top: 0; 
			// top: var(--nav-height, 80px);
			padding: var(--nav-height, 80px) var(--bs-gutter-x,20px) 16px;  
			z-index: 2;
			background: #221E41;
			border: none;
			height: var(--window-height);
			z-index: -1;
		}
		.navbar-nav{
			margin: 48px 0 auto;
		}
		.navbar-wrapper{
			display: flex; 
			flex-direction: column;
			height: 100%;
		}
	}
	.navbar-mobile-footer{
		display: block; 
		color: #fff; 
		margin: 0 0;
		padding-top: 0;
    	width: 100%;
		.footer-contact{
			margin: 0;
			padding: 0 0 40px; 
			font-size: 17px;
			.city{
				font-size: inherit;
				margin: 0 0 16px;
			}
		}
		.foot-nav{
			margin: 0;
			padding: 0 0 50px;
		}
	}
	@media screen and (max-height: 780px){
		.navbar{
			.navbar-nav{
				margin-top: 30px;
			}
		}
		.navbar-mobile-footer{
			.footer-contact{
				padding-bottom: 30px;
			}
			.foot-nav{
				padding-bottom: 30px;
			}
		}
	}
	@media screen and (max-height: 700px){
		.navbar{
			.navbar-nav{
				margin-top: 0;
			}
		}
		.navbar-mobile-footer{
			.footer-contact{
				padding-bottom: 30px;
			}
			.foot-nav{
				padding-bottom: 20px;
			}
		}
	}
}
@media screen and (max-width: breakpoint-max(sm, $grid-breakpoints)){ //default : < 640px
}

// body.scrolled{

// 	@for $i from 1 through $max_scroll_transition {
// 		&[scrolled="#{$i}"]{
// 			.navbar{
// 				background: rgba($nav_bg, calc($nav_bg_opacity/$max_scroll_transition*$i));
// 				height: calc( $nav_height - calc( calc($nav_height - $scrolled_nav_height)/$max_scroll_transition*$i) );

// 				@media screen and (max-width: breakpoint-max(md, $grid-breakpoints)){ //default : < 768px
// 					.navbar-collapse{	
// 						background: rgba($nav_bg, (calc($nav_bg_opacity/$max_scroll_transition*$i) + .8) );
// 					}
// 				}
// 			}
// 		} 
// 	}
// 	.navbar{
// 		background: rgba($nav_bg, $nav_bg_opacity);
// 		height: $scrolled_nav_height;
// 		&:hover{
// 			background: rgba($nav_bg, 1);
// 		}
// 		/* @media screen and (max-width: breakpoint-max(md, $grid-breakpoints)){ //default : < 768px
// 			.navbar-collapse{	
// 				background: rgba($nav_bg, $nav_bg_opacity);
// 				&:hover{
// 					background: rgba($nav_bg, 1);
// 				}
// 			}
// 		} */
// 	}
// }

