.grtyoutube-popup {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grtyoutube-popup-content {
  width:95%;
  max-width:850px;
  margin-left:auto;
  margin-right:auto;
  position:relative;
}

.grtyoutube-popup-close {
  position:absolute;
  top:-30px;
  right:0;
  color:#FFF;
  font-size:25px;
  width:17px;
  height:17px;
  cursor:pointer;
}

.grtyoutube-iframe {
  width:100%;
  height:100%;
}

.grtyoutube-dark-theme{
  background: rgba(0,0,0, 0.85);
}

.grtyoutube-dark-theme .grtyoutube-popup-content {
}


.grtyoutube-light-theme {
  background: rgba(255,255,255, 0.85);
}

.grtyoutube-light-theme .grtyoutube-popup-content {
}

