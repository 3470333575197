.is-ready{
	.header-home{
		.word-mask{
			& > span {
			    -webkit-animation: translate-up-0-masked 0.75s var(--ease-garret) forwards;
			    animation: translate-up-0-masked 0.75s var(--ease-garret) forwards;
			}
		}	
		.title-content{
			.btn{
		        -webkit-animation: fade-in .6s var(--ease-out-soft) 1.3s forwards;
		        animation: fade-in .6s var(--ease-out-soft) 1.3s forwards;
			}
		}
		.header-content{
			-webkit-animation: fade-in .6s var(--ease-out-soft) 1.3s forwards;
			animation: fade-in .6s var(--ease-out-soft) 1.3s forwards;
		}
	}
}
.header-home{
	padding-top: var(--nav-height);
	padding-bottom: 70px;
	min-height: 750px;
	height: var(--window-height);


	.word-mask{
		// margin-top: -1em;
    	vertical-align: middle; 
	    display: inline-block;
	    overflow: hidden;
	    & > span {
		    display: inline-block;
		    transform: translateY(103%);
		    vertical-align: middle;
		}
	}

	.container, .container-fluid{
		display: flex;
		flex-direction: column;
		position: relative; 
		height: 100%;
	}
	.spacer-top{
		height: 21.8%;
		flex: 1;
	}
	.spacer-middle{
		min-height: 70px;
	}
	.title-wrapper{
		display: flex;
		align-items: center;
		flex: 1;
	}
	.title-content{
		margin: auto 0 0;
		h1{
			font-family: 'ClashGrotesk-Variable', sans-serif;
			font-size: 113px;
			line-height: 1.06;
			font-weight: 400;
			letter-spacing: -0.01em; 
			margin: 0;
			transition: all .25s cubic-bezier(.215,.61,.355,1);
			span{
				display: inline-block;
				vertical-align: bottom;
			}
			strong, .word-strong{
				font-weight: 600;
				text-transform: uppercase; 
			}
			display: inline;
		}
		.btn{
			vertical-align: bottom; 
			margin: 0 0 16px 16px;
			opacity: 0;
		}
	}

	.header-content{
		display: flex;
		flex-wrap: wrap;
    	align-items: flex-start;
		position: relative;
		border-top: solid 1px #000;
		padding-top: 25px;
		margin-top: 82px;
		opacity: 0; 
		&::before,&::after{
			content: '';
			position: absolute;
			top: -18px;
			width: 18px;
			height: 18px;
		}
		&::before{
			left: 0;
			border-top-right-radius:100%;
			background: #00ADFF;
		}
		&::after{
			right: 0;
			border-top-left-radius:100%;
			background: #6937FF;
		}

		.hint{
			position: relative;
			display: inline-block;
			color: #777777;
			margin: 0 auto 0 0;
			&::after{
				content: "";
				position: absolute;
				left: 0; right: 0; bottom: -3px;
				height: 0.05em;
				background: currentColor;
				will-change: transform;
				transform-origin: 100% 100%;
				transform: scaleX(0);
				pointer-events: none;
				transition-property: transform;
				transition-timing-function: cubic-bezier(.215,.61,.355,1);
				transition-duration: .5s;
			}
			&:hover{
				&::after{
				    transform-origin: 0 0;
				    transform: scaleX(1);
				}
				.default-text{
					transform: translateY(-125%);
				}
				.hover-text{
					transform: none;
				}
			}
			.nav-link-inner{
				overflow: hidden;
				position: relative;
				display: block;
			}
			.default-text,
			.hover-text{
				display: block;
			    transition-property: transform;
			    transition-timing-function: cubic-bezier(.215,.61,.355,1);
			    transition-duration: .5s;
			}
			.hover-text{
				position: absolute;
				top: 0; left: 0;
				transform: translateY(125%);
			} 
		}
		.description-copy{ 
			display: flex;
			flex: 0 0 var(--nav-collapse-width, 50%);
	    	width: var(--nav-collapse-width, 50%); 
		}
		p.description{
			max-width: 420px;
			margin: 0 auto 0 0;
		}
	}

	@media screen and (min-width: breakpoint-min(xxl, $grid-breakpoints)){ //default : >= 1400px
		padding-bottom: 7rem;
		.header-content{
			margin-top: 8.2rem;
			&::before,&::after{
				top: -1.8rem;
				width: 1.8rem;
				height: 1.8rem;
			}
			p.description{
    			max-width: 42rem;
    		}
    	}
    	.title-content{
    		margin-top: auto;
			h1{
			    font-size: 11.3rem; 
			}
    		.btn{
    			margin: 0 0 1.6rem 1.6rem; 
    		}
    	}
	}
	
	@media screen and (min-width: 1900px){ //default : >= 1900px
		//default height : 1200
		// rem : 13.333
		padding-bottom: 5.40rem;
		.header-content{
			margin-top: 7.2rem;
    	}
    	.title-content{
	 		h1{
			    font-size: 10.9rem; 
			}
    		.btn{
    			margin: 0 0 1.2rem 1.6rem; 
    		}
    	}
	}
	@media screen and (min-width: 2500px){ //default : >= 2500px
		//default height : 1440
		// rem : 17.778 
		padding-bottom: 6.75rem;
		.header-content{
			margin-top: 6.75rem;
    	}
    	.title-content{
	 		h1{
			    font-size: 10.63rem; 
			}
    		.btn{
    			margin: 0 0 0.9rem 1.6rem; 
    		}
    	} 
	}
	
	@media screen and (max-width: breakpoint-max(xxl, $grid-breakpoints)){ //default : < 1400px
	}
	@media screen and (max-width: 1250px){ //default : <= 1250px
		.title-content{
			h1{
				font-size: 110px; 
			}
		}
	}
	@media screen and (max-width: breakpoint-max(xl, $grid-breakpoints)){ //default : < 1200px
		.title-content{
			h1{
				font-size: 90px;
			}
			.btn{
				margin-bottom: 8px;
				margin-left: 25px;
			}
		}
	}
	@media screen and (max-width: breakpoint-max(lg, $grid-breakpoints)){ //default : < 992px
		min-height: 850px;
		.title-content{
			h1{
				font-size: 4rem;
				.splitter-wrap{
					// display: block; 
				}
			}
			.btn{
				margin: 0;
			}
		}
		.header-content{
			.description-copy{ 
				flex-wrap: wrap;
			}
			p.description{
				min-width: 100%;
				padding: 0;
				margin-bottom: 0.615rem;
			}
		} 

	}
	@media screen and (max-width: breakpoint-max(md, $grid-breakpoints)){ //default : < 768px
		padding-bottom: 3.2rem; 
		// padding-bottom: 3.2rem;  
		// .title-content{
		// 	h1{
		// 		// font-size: 75px;
		// 		font-size: 4rem;
		// 		// line-height: 1.06;
		// 		display: block;
		// 		margin-bottom: 2.4rem; 
		// 		.splitter-wrap{
		// 			display: block;
		// 		}
		// 	}
		// }
		// .header-content{
		// 	padding-top: 2.4rem;
		// 	.description-copy{
		// 		margin-top: 15px;
		// 		flex-wrap: wrap;
		// 	}
		// 	.hint{
		// 		display: none; 
		// 	}
		// }
		.title-content{
			h1{
				margin-bottom: 2.4rem; 
				display: block;
			}
		}
		.header-content{
			.hint, p.description{
				margin-bottom: .8rem; 
			}
		}
	}
	@media screen and (max-width: breakpoint-max(sm, $grid-breakpoints)){ //default : < 640px
		min-height: initial;
		// min-height: 650px;
		// h1{
		// 	// font-size: 55px; 
		// 	// line-height: 1.25;
		// }
		// .title-content{
		// 	// margin-top: 50px;
		// }
		// .header-content{
		// 	.hint{
		// 		position: absolute;
		// 		top: 25px;
		// 	}
		// 	.description-copy{
		// 		margin-top: 0;
		// 	}
		// }
		.header-content{
			line-height: 1.67;
			margin-top: 4.8rem;
			.hint{
				display: none;
			}
			p.description, .copy{
				font-size: inherit;
				line-height: inherit;
			}
		}  

	}
	@media screen and (max-width: 479.98px){ //default : < 480px
		// h1{
		// 	// font-size: 40px;
		// }
		// .title-content{
		// 	.btn{
		// 		// margin-left: 15px;
		// 		// vertical-align: top;
		// 	}
		// }
		// .header-content{
		// 	font-size: 15px; 
		// 	line-height: 1.67;
		// 	p.description{
		// 		min-width: 100%;
		// 		padding: 0;
		// 		margin-bottom: 2.4rem; 
		// 	}
		// 	.hint, p.description, .copy{
		// 		font-size: inherit;
		// 		line-height: inherit;
		// 	}
		// }
		.header-content{
			font-size: 1.5rem;
			p.description{
				margin-bottom: 2.4rem; 
			}
		}
	}
}
