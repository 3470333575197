body{
	--company-color: #777;
	&.bg-gradient{
		--company-color: #fff;
		.testimonials{
			color: #fff;
			background: transparent;

			// @media screen and (min-width: breakpoint-min(sm, $grid-breakpoints)){ //default : >= 640px
				.testimonial-item.big{
					.play-video{
						i{
							background-image: url('../img/ic-play-circle-filled-2.svg');
						}
					}
				}
			// } 
		}
	}
}
.testimonials{
	color: #000;
	padding-top: 140px;
	padding-bottom: 60px;
	background: #f4f4f4;
	transition: background cubic-bezier(0.72,0,0.28,1) .65s;
	.section-title{
	    display: flex;
	    align-items: center;
		margin-bottom: 0;
		padding-bottom: 72px; 
		border-bottom: solid 1px currentColor; 
		&::after{
			content: '';
			position: absolute;
			right: 0;
			width: 48px; height: 40px;
			background-image: url('../img/ic-quote.svg');
			background-repeat: no-repeat;
			background-position: center;
    		background-size: contain;
		}
	}

	.testimonial-list{
		display: flex;
    	flex-wrap: wrap;
    	margin: 0 -31px 0 81px;
	}
	.testimonial-container{
		width: 50%;
		flex: 0 0 50%;
		padding: 0 31px;
		&.full-width{
			width: 100%;
			flex: 0 0 100%;
		}
	}
	.testimonial-item{
		border-top: solid 1px currentColor;
		padding-top: 30px;
		padding-bottom: 80px; 
		margin: 0;
		p{
			font-size: 19px;
			line-height: 1.74;
			margin-bottom: 24px;
			transition: color cubic-bezier(0.72,0,0.28,1) .65s;
		}
		.testimonial-footer{
			display: flex;
			align-items: center;
		}
		.profile{
			display: flex;
			align-items: center;
			gap: 15px;
			.avatar{
				width: 35px; height: 35px;
				border-radius: 50%;
				overflow: hidden;
				img{
					width: 100%; height: 100%;
					object-fit: cover;
				}
			}
			.author-company{
				display: flex;
				align-items: center;
				gap: 15px;
				cite{
					font-style: normal;
					transition: color cubic-bezier(0.72,0,0.28,1) .65s;
				}
				.author{
					font-size: 21px;
					font-weight: 600;
				}
				.company{
					color: var(--company-color, #777777);
				}
			}
		}
		.play-video{
			margin-left: auto;
			font-size: 17px;
			line-height: 1.76;
			font-weight: 500;
			overflow: hidden;
			text-decoration: underline; 
			transition: all cubic-bezier(.215,.61,.355,1) .5s;
			i{
				float: right;
				width: 17px;
				height: 1.76em;
				margin-left: 5px;
				display: inline-block;
				vertical-align: middle;
				background-image: url('../img/ic-play-circled-1.svg');
			    background-repeat: no-repeat;
			    background-position: center;
			    transition: all cubic-bezier(.215,.61,.355,1) .5s;
			    margin-right: -40px;
			}
			&:hover, &:focus{
				color: #6937FF;
				text-decoration: none;
				i{
					margin-right: 0;
				}
			}
			&:focus{
				color: #5f31e6;
				i{
					background-image: url('../img/ic-play-circled-2.svg');
				}
			}
		}

		&.big{
			border-top: none;
			// @media screen and (min-width: breakpoint-min(sm, $grid-breakpoints)){ //default : >= 640px
				p{
					font-size: 37px;
					line-height: 1.57;
					margin-bottom: 25px;
				} 
				@media screen and (max-width: breakpoint-max(md, $grid-breakpoints)){ //default : < 768px
					p{
						font-size: 25px;
						line-height: 1.7;
					}
 				} 
				.play-video{
					letter-spacing: 0.02em;
					padding: 12px 24px;
					border: 1px solid currentColor;
					border-radius: 100px;
					text-decoration: none;
					line-height: 1.4;
					i{
						margin: 0 0 0 10px;
						width: 24px; height: 24px;
						background-image: url('../img/ic-play-circle-filled-1.svg');
					    background-repeat: no-repeat;
					    background-position: center;
					}
					&:hover, &:focus{
						color: #FFFFFF;
						background: #6937FF;
						border-color: transparent;
						i{
							background-image: url('../img/ic-play-circle-filled-2.svg');
						}
					}
					&:focus{
						background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #6937FF;
					}
				}
			// }
			// @media screen and (min-width: breakpoint-min(lg, $grid-breakpoints)){ //default : >= 768px
				.profile{
					gap: 25px;
					.avatar{
						width: 75px;
						height: 75px;
					}
					.author-company{
						flex-direction: column;
						align-items: flex-start;
						gap: 7px;
						.author{
							font-size: 25px;
							line-height: 1.32;
						}
						.company{
							font-size: 17px;
							line-height: 1.3; 
						}
					}
				}
			// }
		}
	}
	@media screen and (min-width: breakpoint-min(xxl, $grid-breakpoints)){ //default : >= 1400px
		padding-top: 14rem;
		padding-bottom: 6rem;
		.section-title{
			margin-bottom: 0;
			padding-bottom: 7.2rem;
			&::after{
				width: 4.8rem;
				height: 4rem;
			}
		}
		.testimonial-list{
			margin-bottom: 0 -3.1rem 0 8.1rem;
		}
		.testimonial-container{
			padding: 0 3.1rem;
		}
		.testimonial-item{
			padding-top: 3rem;
			padding-bottom: 8rem;
			p{
				font-size: 1.9rem;
				margin-bottom: 2.4rem;
			}
			.profile{
				gap: 1.5rem;
				.avatar{
					width: 3.5rem; height: 3.5rem;
				}
				.author-company{
					gap: 1.5rem;
					.author{
						font-size: 2.1rem;
					}
				}
			}
			.play-video{
				font-size: 1.7rem;
				i{
					width: 1.7rem;
					margin-left: .5rem;
				    margin-right: -4rem;
				    background-size: contain;
				}
			}

			&.big{
				p{
					font-size: 3.7rem;
					margin-bottom: 3.2rem;
				} 
				.play-video{
					padding: 1.2rem 2.4rem;
					border-radius: 10rem;
					i{
						margin: 0 0 0 1rem;
						width: 2.4rem; height: 2.4rem;
					}
				}
				.profile{
					gap: 2.5rem;
					.avatar{
						width: 7.5rem;
						height: 7.5rem;
					}
					.author-company{
						gap: .7rem;
						.author{
							font-size: 2.5rem;
						}
						.company{
							font-size: 1.7rem;
						}
					}
				}
			}
		}
	}
	
	@media screen and (min-width: 1900px){ //default : >= 1900px 
		//default height : 1200
		// rem : 13.333
		padding-top: 13.5rem;
		padding-bottom: 5.5rem;
	}
	@media screen and (min-width: 2500px){ //default : >= 2500px
		//default height : 1440
		// rem : 17.778 
	}

	@media screen and (max-width: breakpoint-max(xxl, $grid-breakpoints)){ //default : < 1400px
	}
	@media screen and (max-width: breakpoint-max(xl, $grid-breakpoints)){ //default : < 1200px
		.testimonial-list{
			margin-left: -31px;
		}
	}
	@media screen and (max-width: breakpoint-max(lg, $grid-breakpoints)){ //default : < 992px
		.testimonial-container{
			width: 100%;
			flex: 0 0 100%;
		}
		.testimonial-item{ 
			padding-bottom: 60px;

			&.big{ 
				// @media screen and (min-width: breakpoint-min(sm, $grid-breakpoints)){ //default : >= 640px
					p{
						font-size: 32px;
					}
			}
		}
	}
	@media screen and (max-width: breakpoint-max(md, $grid-breakpoints)){ //default : < 768px
		padding-top: 130px;
		.section-title{
			margin-bottom: 70px;
		}
	}
	@media screen and (max-width: breakpoint-max(sm, $grid-breakpoints)){ //default : < 640px
		padding-top: 72px;
		padding-bottom: 24px;
		.section-title{
			padding-right: 50px;
			padding-bottom: 48px; 
			margin-bottom: 0;
			&::after{
				width: 32px;
				height: 27px;
				top: 0;
			}
		}
		.testimonial-item{ 
			padding-top: 24px;
			padding-bottom: 48px; 
			p{
				font-size: 17px; 
				line-height: 1.58;
				margin-bottom: 16px;
			}
			.testimonial-footer{
				align-items: flex-start;
			}
			.profile{
			    align-items: center;
			    gap: 12px;
				.author-company{
					flex-direction: column;
    				align-items: flex-start;
					gap: 3px;
					line-height: 1.3; 
					.author{
						font-size: 15px;
					}
					.company{
						font-size: 13px;
					}
				}
			}
			.play-video{
				font-size: 15px;
			}
			&.big{
				p{
					font-size: 22px;
					line-height: 1.5;
					margin-bottom: 24px;
				}
				.testimonial-footer{
					flex-direction: column;
					gap: 40px;
					.play-video{
						width: 100%;
						flex: 1;
						display: flex;
						align-items: center;
						justify-content: center;
						i{
							margin-right: -10px;
						}
					}
				}
				.profile{
					gap: 12px;
					.avatar{
						width: 65px; height: 65px; 
					}
					.author-company{ 
						gap: 4px;
						.author{
							font-size: 22px;
						}
						.company{
							font-size: 15px;
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 479.98px){ //default : < 480px
	}
}

