.btn{
	padding: 7px 18px 6px;
	font-size: 16px;
	line-height: 1.5; 
	border-radius: 10px;
	border: none; outline: none;
}
.btn-talk{
	position: relative;
	font-weight: 600;
	font-size: 25px;
	line-height: 1.2;
	border: 1px solid #FFFFFF;
	border-radius: 100px;
	padding: 28.5px 52px;
	transition: color cubic-bezier(.215,.61,.355,1) .5s;
	overflow: hidden;
	&::before{
		content: '';
		width: 100%; height: 100%;
		background: conic-gradient(from 90deg at 72.76% 50.51%, #00ADFF -44.85deg, #221E41 89.6deg, #6937FF 225.35deg, #00ADFF 315.15deg, #221E41 449.6deg);
		position: absolute;
		top: 0; left: 0;
		z-index: 1;
		opacity: 0;
    	border-radius: 100px;
		transition: all cubic-bezier(.215,.61,.355,1) .5s;
	}
	&.btn-black{
		color: #000;
		border-color: #000;	
	}
	&.btn-white{
		color: #fff;
		border-color: #fff;
	}
	&:hover, &:focus{
		text-decoration: none;
		color: #fff;  
		border-color: transparent;
		box-shadow: none;
		&::before{
			opacity: 1;
		}
	}
	&:focus{
		&::before{
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), conic-gradient(from 90deg at 72.76% 50.51%, #00ADFF -44.85deg, #221E41 89.6deg, #6937FF 225.35deg, #00ADFF 315.15deg, #221E41 449.6deg);
		}
	}
	span, i{
		position: relative;
		z-index: 2;
	}
	i{
		font-style: normal;
		margin-left: 24px;
	}
}
@media screen and (min-width: breakpoint-min(xxl, $grid-breakpoints)){ //default : >= 1400px
	//default height : 900
	// rem : 10
	.btn{
		font-size: 1.6rem;
	}
	.btn-talk{
		font-size: 2.5rem;
		padding: 2.85rem 5.2rem;
	}
}

@media screen and (min-width: 1900px){ //default : >= 1900px
	//default height : 1200
	// rem : 13.333
	.btn-talk{
		font-size: 2.7rem;
		padding: 3rem 5.5rem;
	}
}

@media screen and (max-width: breakpoint-max(md, $grid-breakpoints)){ //default : < 768px
	.btn-talk{
		padding: 26px 50px;
	}
}
@media screen and (max-width: breakpoint-max(sm, $grid-breakpoints)){ //default : < 640px
	.btn-talk{
		padding: 21px 38px; 
		font-size: 18.1818px;
		i{
			margin-left: 1em;
		}
	}
}
@media screen and (max-width: 479.98px){ //default : < 480px
}


